<script setup>
const nuxtApp = useNuxtApp();

const props = defineProps(['modalId']);

const slotRef = ref(null);
const showLinks = ref(false);


const onClick = () => {
    useMetrika().goal("SUSHI_POPUP_CLICK");
    close();
    useRouter().push('/#sushi');
};

nuxtApp.hook("page:finish", () => {
    if (process.client) {
        setTimeout(() => {
            if (slotRef.value != null) {
                slotRef.value.classList.add("show");
            }

        }, 50);
    }
});

const close = () => {
    nuxtApp.$modalsBus.$emit(`modals.sushi-popup:close`);
}


</script>
<template>
    <DModal @close="close" class="sushi-d-modal">
        <div class="sushi-popup">
            <div @click="close" class="close-button"><i class="bi bi-x"></i></div>
            <div class="banner">
                <NuxtLink @click.prevent="onClick">
                    <img src="http://static.dodomino.ru/sushi-popup.png" alt="Domino Sushi">
                </NuxtLink>
            </div>
        </div>
    </DModal>
</template>

<style lang="scss">
.sushi-d-modal {
    .d-modal {
        .d-modal-container {

            &::after {
                content: "";
                max-height: 40px;

                @media screen and (max-width: 565px) {
                    content: none;
                }
            }

            .d-modal-content {
                max-width: 460px;
            }
        }

    }
}

.sushi-popup {

    .banner {
        cursor: pointer;

        img {
            width: 100%;
        }
    }

    .close-button {
        position: absolute;
        right: 4px;
        top: 4px;

        width: 40px;
        height: 40px;

        padding: 4px;
        font-size: 30px;
        line-height: 0;
        text-align: center;
        cursor: pointer;
        z-index: 100;

        i {
            display: block;
            position: relative;
            top: 50%;
            transform-origin: center;
            transform: scale(1) translate(0, -50%);
            color: #fff;
        }
    }
}
</style>