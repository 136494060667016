<script setup>
import { useAuthStore } from '~/stores/auth';
import { useProductStore } from '~/stores/product';

const props = defineProps(['item']);

const addSimpleLoading = ref(false);

const config = useRuntimeConfig();

const menuItem = (item) => {
    return item.product || item.group;
};

const menuType = (item) => {
    return item.product ? 'product' : 'group';
};

const menuItemPrice = (item) => {
    if (menuType(item) == 'group') {
        return `от ${menuItem(item).products[0].price}₽`;
    }

    if (menuItem(item).no_price_on_page === true) {
        return '';
    }

    if (menuItem(item).display_price) {
        return menuItem(item).display_price;
    }

    return `${menuItem(item).price}₽`;
};

const menuItemDescription = (item) => {
    if (menuType(item) == 'group') {
        if (menuItem(item).hide_description) {
            return null;
        }

        return menuItem(item).products[0].description;
    }

    return menuItem(item).description;
};

const menuItemName = (item) => {
    if (menuType(item) == 'group') {
        return menuItem(item).name;
    }

    return menuItem(item).name_with_size;
};

const goTo = (item) => {
    if (item.group) {
        return `/groups/${menuItem(item).slug}`;
    }

    if (item.product.is_pizza_halves === true) {
        return `/halves/${menuItem(item).slug}`;
    }

    return `/products/${menuItem(item).slug}`;
};

const descriptionLines = () => {
    var descriptionLines = 3;
    let name = document.getElementById(`name-${menuType(props.item)}-${menuItem(props.item).id}`);

    if (name.offsetHeight > 60) {
        descriptionLines = 2;
    } else if (name.offsetHeight > 25) {
        descriptionLines = 3;
    }

    return descriptionLines;

};

const formatDescription = () => {
    return;
    var divh = 70;
    var descriptionLines = 3;
    let name = document.getElementById(`name-${menuType(props.item)}-${menuItem(props.item).id}`);

    if (!name) {
        return;
    }

    if (name.offsetHeight > 60) {
        divh = 20;
        descriptionLines = 2;
    } else if (name.offsetHeight > 25) {
        divh = 45;
        descriptionLines = 1;
    }

    let p = document.getElementById(`description-${menuType(props.item)}-${menuItem(props.item).id}`);
    p.textContent = menuItemDescription(props.item);

    p.parentElement.style.cssText = `height: ${divh}px;`;
    while (p.offsetHeight > divh) {
        p.textContent = p.textContent.slice(0, p.textContent.lastIndexOf(" ")) + "...";
    }
};

if (process.client) {
    const throttledResize = useThrottleFn(() => {
        formatDescription();
    }, 1000);

    onMounted(() => {
        formatDescription();
        window.addEventListener("resize", throttledResize);
    });

    onDeactivated(() => {
        window.removeEventListener("resize", throttledResize);
    });
}

const cardClick = () => {
    useMetrika().goal("MAIN_PAGE_PRODUCT_CARD_CLICK");
};

const addToCart = (item) => {
    const authStore = useAuthStore();

    // if (!authStore.user) {
    //     return navigateTo('/auth/login');
    // }

    // if (!authStore.user.registered) {
    //     return navigateTo('/auth/register');
    // }

    if (!item.simple) {
        return;
    }

    const productStore = useProductStore();
    productStore.initWithProduct(menuItem(item));

    addSimpleLoading.value = true;
    productStore.addToCart().then(() => {
        addSimpleLoading.value = false;
    });

    return;
};

</script>

<template>
    <NuxtLink @click="cardClick" :key="`product-card-${menuItem(item).id}-${menuItem(item).slug}`" :to="goTo(item)"
        class="product-card-link">
        <div class="product-card">
            <CatalogProductCardImage :imageUrl="menuItem(item).image_url" :imageSet="menuItem(item).image_set"
                :name="menuItemName(item)" />

            <div v-if="menuItem(item).label_image_url != null" class="product-label"
                :style="{ 'background-image': `url(${menuItem(item).label_image_url})` }"></div>
            <div class="product-card-content">
                <div :id="`name-${menuType(item)}-${menuItem(item).id}`" class="product-name"> {{
                    menuItemName(item) }}</div>
                <div class="description-container">
                    <ClientOnly>
                        <p :id="`description-${menuType(item)}-${menuItem(item).id}`"
                            :class="`description-l-${descriptionLines()}`" class="product-description">
                            {{ menuItemDescription(item) }}
                        </p>
                        <template #fallback>
                            <p :id="`description-${menuType(item)}-${menuItem(item).id}`"
                                class="product-description description-l-2">
                                {{ menuItemDescription(item) }}
                            </p>
                        </template>
                    </ClientOnly>
                </div>

                <div class="product-info">
                    <div class="price">{{ menuItemPrice(item) }}</div>
                    <div class="button">
                        <DButton v-if="item.simple === true" :light="config.public.isDomino ? true : false"
                            :loading="addSimpleLoading" :preventDefault="true" @onTap="addToCart(item)">В
                            корзину</DButton>
                        <DButton v-else :light="config.public.isDomino ? true : false">Выбрать</DButton>
                    </div>
                </div>
            </div>
        </div>
    </NuxtLink>
</template>

<style lang="scss" scoped>
.product-card-link {
    text-decoration: none;
}

.product-card {
    position: relative;
    background-color: #fff;
    border-radius: if($is-domino, 10px, 10px);
    padding: 10px;
    display: flex;
    flex-direction: column;
    // margin-bottom: 20px;
    cursor: pointer;
    overflow: hidden;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.01);
    height: 100%;
    border: 1px solid #fff;

    @if $is-domino {
        transition: all 0.5s;
    }

    @if not $is-domino {
        border: 1px solid #000;
        transition: all 0.2s;
    }

    @media screen and (max-width: 577px) and (min-width: 360px) {

        .product-image,
        .product-picture {
            transform: none !important;
            max-width: 130px;
            max-height: 130px;
            margin-top: auto;
            margin-bottom: auto;
            margin-right: 16px;
        }
    }

    @media screen and (max-width: 360px) and (min-width: 320px) {

        .product-image,
        .product-picture {
            transform: none !important;
            max-width: 100px;
            max-height: 100px;
            margin-top: 0;
            margin-bottom: auto;
            margin-right: 16px;
        }

        .d-button {
            font-size: 12px;
        }
    }

    @media screen and (max-width: 577px) and (min-width: 320px) {
        flex-direction: row;
        transform: none !important;

        .product-name {
            line-height: 1.1 !important;
        }

        .product-info {
            .price {
                font-size: 14px !important;
            }
        }
    }

    &:hover {
        @if $is-domino {
            transform: scale(1.02);
            transition: all 0.5s;
        }

        @else {
            transform: scale(1.02);
            transition: all 0.2s;
        }
    }

    .product-card-content {
        display: flex;
        flex-direction: column;
        height: 100%;

        @media screen and (max-width: 577px) and (min-width: 320px) {
            width: 100%;
        }
    }

    .product-label {
        position: absolute;
        top: 8px;
        right: 8px;
        width: 100px;
        height: 60px;
        background-size: contain;
        background-repeat: no-repeat;

        @media screen and (max-width: 577px) and (min-width: 320px) {
            top: 8px;
            left: 8px;
            width: 80px;
            height: 40px;
        }
    }

    .product-image {
        background-size: cover;
        border-radius: 10px;
        background-position: 50% 50%;
        transition: all 0.4s;

        @media screen and (max-width: 577px) and (min-width: 320px) {
            // width: 100px;
        }
    }

    .product-name {
        font-size: 20px;
        line-height: 25px;
        font-weight: bold;
        text-overflow: ellipsis;
        text-align: left;
        margin-top: 10px;
        margin-bottom: 10px;

        color: #000;

        @media (max-width: 768px) {
            font-size: 16px;
        }

    }

    .description-container {
        overflow: hidden;
        margin-bottom: 20px;

        .product-description {
            display: block;
            font-size: 14px;
            font-weight: normal;
            line-height: 15px;

            color: #646464;
            text-decoration: none;
            margin: 0;
            padding: 0;

            @media screen and (max-width: 360px) and (min-width: 320px) {
                font-size: 12px;
            }

            &.description-l-1 {
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
            }

            &.description-l-2 {
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }

            &.description-l-3 {
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
            }

        }
    }


    .product-info {
        display: flex;
        margin-top: auto;
        text-align: left;

        .price {
            font-size: 20px;
            font-weight: 800;
            color: #000;
            margin-top: auto;
            margin-bottom: auto;
        }

        .button {
            margin-left: auto;
            margin-top: auto;
            margin-bottom: auto;
        }
    }
}
</style>